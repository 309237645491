<template>
  <div class="container">
    <div class="header">
      <div class="left">
        <div class="title">硬件中心</div>
        <div class="content">灵活应用于政府、教育、安防等行业场景，满足身份
          核验、人脸考勤、闸机通行等业务需求，
          准确识别多种属性信息，精确判断用户身份真实性，
          通过软硬件结合，为客户提供全方位简洁高效的服务。
        </div>
      </div>
      <img class="right" src="../../../static/img/product-center/hardimg.png"
        alt="">
    </div>
    <div class="footer">
      <div class="card" v-for="(item,i) in cardArr" :key="i">
        <div class="card_intro">
          <div class="card_img">
            <img
              :src="require('../../../static/img/product-center/' + item.url + '.png')"
              alt="">
          </div>
          <div class="card-info">
            <div class="card-title">{{item.title}}</div>
            <div class="card-detail">{{item.content}}</div>
          </div>
        </div>
        <div class="card_hover">
          <span>{{item.title}}</span>
          <div v-html="item.detail"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      cardArr: [
        {
          title: 'SensePass 智能人脸识别门禁机',
          content: '支持本地人员库 50000 人、暗光识别、红外活体检测... ',
          url: 'hard1',
          detail: '300 毫秒即可完成人脸识别，识别准确率高于 99%实时检测和跟踪人脸，在侧脸、半遮挡、模糊等情景下均能进行精准检测支持本地人员库 50000 人 支持暗光识别 红外活体检测 '
        },
        {
          title: 'SensePass pro 智行人脸识别一体机',
          content: '支持刷脸验证、二维码、IC卡，身份证多种模式组合...',
          url: 'hard2',
          detail: '300 毫秒即可完成人脸识别，识别准确率高于 99%支持本地人员库 50000 人多场景使用:门禁，闸机，考勤，迎宾 支持刷脸验证、二维码、IC卡，身份证多种模式组合使用 支持设备强拆、强制开门，门磁超时、密码破解和非活体攻击的异常告警上报'
        },
        {
          title: 'Sense Keeper 人脸识别机 （闸机版）',
          content: '将人员信息提前录入系统，设备现场采集行人的人脸信...',
          url: 'hard3',
          detail: '300 毫秒即可完成人脸识别，识别准确率高于 99%支持本地人员库 50000 人将人员信息提前录入系统，设备现场采集行人的人脸信息， 将其与库中的信息进行比对，如果信息能正确匹配，则通过验证。  人员管理、访客管理、设备管理、员工考勤、员工批量 入库、人员组管理、通行策略管理、通行记录查询、数据报表导等功能。'
        },
        {
          title: 'SenseID-020 身份验证一体机',
          content: '身份证读卡器可快速、准确地读取身份证芯片里的照片... ',
          url: 'hard4',
          detail: '身份证读卡器可快速、准确地读取身份证芯片里的照片、文字、指纹等信息现场抓取人脸图像，检测、提取人脸生物特征。利用人脸识别技术，将摄像头抓拍的现场人脸图像和读取到的身份证照片进行比对，判断人、 证是否一致。对身份证进行拍照，自动提取身份证的文字信息，并与身份证芯片信息对比，判断其真伪。'
        },
        {
          title: 'SenseID-030 身份验证一体机',
          url: 'hard5',
          content: '身份证读卡器可快速、准确读取身份证芯片里的照片、文字等信息',
          detail: '身份证读卡器可快速、准确读取身份证芯片里的照片、文字等信息; 注:身份证读卡器可读取证件如下 1、 二代身份证2、 港澳台居民居住证 3、 外国人永久居留证 真人检测:用户站在摄像头前无需任何配合，设备即可分辨是否为真人操作，或者高清 图片、PS、三维模型、换脸等仿冒欺诈手段。 OCR功能(双屏版，型号SID030-DS功能):通过OCR光学识别技术，可自动提取常规 护照及驾驶证信息，将证件照片与现场人脸进行比对，判断人证一致性; 自动抓取人脸图像，实时检测和跟踪人脸，在侧脸、半遮挡、模糊等情景下 均能迅速进行精准检测; '

        },
        {
          title: 'SensePass C 简行人脸识别一体机',
          content: '误识率≤万分之一 ，戴墨镜、戴帽子、蓄须等遮挡情况...',
          url: 'hard6',
          detail: '误识率≤万分之一戴墨镜、戴帽子、蓄须等遮挡情况下依然保持精准识别自动曝光、抗强逆光等算法，可在多种光线环境下实现精准识别 安全帽识别，摄像头灰尘污染识别'
        },
        {
          title: 'SensePass X 畅行人脸识别一体机',
          content: '8英寸多点触摸屏幕，户外无补光灯设计，夜间不晃眼...',
          url: 'hard7',
          detail: '8英寸多点触摸屏幕，户外无补光灯设计，夜间不晃眼 隐藏式屏下刷卡设计，美观实用 支持0.5m-2.0m识别距离支持前端后端混合识别 '
        },
        {
          title: 'Sense Nebula-M 智能边缘节点',
          content: '轻量化、低功耗设计，安装简单，无需专业的机房环境...',
          url: 'hard8',
          detail: '轻量化、低功耗设计，安装简单，无需专业的机房环境支持市场主流网络摄像机和抓拍机混合接入基于专业边缘计算GPU的深度学习算法、单台设备最大可支持8路视频流，或16路图片流接入 • 最大支持30万张黑/白名单库硬件加密设计，数据加密存储'
        },
        {
          title: '200w 人脸抓拍半球型摄像机',
          content: '内嵌深度学习算法，利用海量数据训练而实现。优秀的...',
          url: 'hard9',
          detail: '内嵌深度学习算法，利用海量数据训练而实现 优秀的人脸检测跟踪及属性识别性能 支持人脸区域识别、区域屏蔽、人脸 ID 统计 以及多种人脸参数设置采用最新标准 H.265+ 视频压缩技术，压缩比高，码流控制准确、稳定 支持每帧最多 32 个人脸检测 、支持人脸 30*30-500*500 像素之间的人脸检测'
        },
        {
          title: '200w 人脸抓拍一体筒型摄像机',
          content: '支持人脸抓拍功能，支持对运动人脸进行检测、跟踪...',
          url: 'hard10',
          detail: '支持人脸抓拍功能，支持对运动人脸进行检 测、跟踪、抓拍、评分、筛选，输出最优的人 脸抓图  支持人脸区域识别、区域屏蔽、人脸 ID 统计 以及多种人脸参数设置支持人脸抓拍功能，支持对运动人脸进行检 测、跟踪、抓拍、评分、筛选，输出最优的人 脸抓图 '
        }
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  min-width: 1200px;
  .header {
    background: #f6f8fb;
    height: 2.1875rem;
    opacity: 1;
    padding: 0.270833rem 1.875rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 3.015625rem;
      padding-top: 0.270833rem;
      .title {
        font-size: 0.1875rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.15625rem;
        color: #000000;
        opacity: 0.87;
        padding-bottom: 0.15625rem;
      }
      .content {
        font-size: 0.104167rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.1875rem;
        color: #000000;
        // letter-spacing: 75px;
        opacity: 0.6;
      }
    }
    .right {
      width: 2.604167rem;
      height: 1.651042rem;
    }
  }
  .footer {
    // padding: 0.291667rem 1.875rem;
    padding: 0.270833rem 1.875rem;
    // padding: 0.291667rem 1.984375rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // width: 100%;
    .card {
      margin-bottom: 0.208333rem;
      margin-right: 2%;
      width: 31%;
      height: 1.567708rem;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
      opacity: 1;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      .card_intro {
        .card_img {
          width: 100%;
          height: 0.9375rem;
          background: #f6f8fb;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            max-width: 1.302083rem;
            max-height: 0.677083rem;
          }
        }
        .card-info {
          text-align: center;
          .noimg {
            padding: 0.104167rem;
            .title {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 30px;
              color: #ffffff;
              opacity: 0.87;
            }
            .content {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 30px;
              color: #ffffff;
              opacity: 0.87;
            }
          }
          .card-title {
            font-size: 0.083333rem;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 0.15625rem;
            color: #000000;
            opacity: 0.87;
            padding-top: 0.15625rem;
            padding-bottom: 0.03125rem;
          }
          .card-detail {
            font-size: 0.072917rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0.15625rem;
            color: #000000;
            opacity: 0.6;
            padding: 0 0.104167rem;
            // max-width: 1.822917rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .card_hover {
        // visibility: hidden;
        width: 100%;
        height: 1.567708rem;
        background: #4285f4;
        opacity: 0.87;
        border-radius: 4px;
        color: #fff;
        transition: transform 0.3s;
        text-align: center;
        overflow: hidden;
        padding-top: 0.104167rem;
        padding-bottom: 0.052083rem;
        position: absolute;
        top: 1.567708rem;
        left: 0;
        transition: all 0.3s;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 9;
        // -webkit-box-orient: vertical;
        span {
          font-size: 0.083333rem;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0.15625rem;
          color: #ffffff;
          opacity: 0.87;
          // padding-top:0.104167rem;
        }
        div {
          height: 1.567708rem;
          text-align: left;
          height: 230px;
          vertical-align: middle;
          // width: 1.802083rem;
          padding: 0.078125rem 0.104167rem 0.052083rem 0.104167rem;
          font-size: 0.072917rem;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0.15625rem;
          color: #ffffff;
          opacity: 0.87;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          @media screen and (max-width: 1775px) {
            height: 210px;
          }
        }
      }
      &:hover .card_hover {
        // visibility:visible;
        // transform: translate(0, -1.90625rem);
        top: 0;
      }
    }
  }
}
</style>
